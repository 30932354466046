import React, { useEffect, useRef, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { sendContent } from '../../../services/stories/postContentService';
import Resizer from "react-image-file-resizer";
import AvatarSort from '../../../components/AvatarSort/AvatarSort';
import { AVATAR_FILES } from '../../..';
import imgGarotaCelular from '../../../images/imagem-garota-celular.png';
import frameDefault from '../../../images/frame-default.png';
import AnimaBackground from '../../../components/AnimaBackground/AnimaBackground';
import Assinatura from '../../../components/Assinatura/Assinatura';
import ReCAPTCHA from "react-google-recaptcha";
import FramePhoto from '../../../components/FramePhoto/FramePhoto';
import Opcional from '../../../components/Opcional/Opcional';
import PoliticaTermos from '../../../components/PoliticaTermos/PoliticaTermos';
import ModalComecar from '../../../components/ModalComecar/ModalComecar';
import useWebSocket from 'react-use-websocket';

function Envio() {

    const url = useParams();

    const loadStartRef = useRef();
    const mediaRef = useRef();
    const nameRef = useRef();
    const avatarRef = useRef();
    const legendaRef = useRef();
    const recaptchaRef = useRef();
    const btnSubmitRef = useRef();
    const divCommentRef = useRef();
    const commentRef = useRef();

    const [isMobile, setIsMobile] = useState(false);
    const [socket, setSocket] = useState(null);
    const [stLoadStart, setStLoadStart] = useState(true);
    const [comecar, setComecar] = useState(true);
    const [step, setStep] = useState(1);
    const [MAX_ENVIOS, setMAX_ENVIOS] = useState(2);
    const [nome, setNome] = useState('');
    const [media, setMedia] = useState('');
    const [avatarMedia, setAvatarMedia] = useState('');
    const [avatarDraw, setAvatarDraw] = useState('');
    const [files, setFiles] = useState();
    const [envios, setEnvios] = useState(0);
    const [showModal, setShowModal] = useState(false);
    const [disabled, setDisabled] = useState(true);
    const [message, setMessage] = useState('');
    const [sent, setSent] = useState(false);
    const [labelClass, setLabelClass] = useState('');
    const [labelAvatarClass, setLabelAvatarClass] = useState('envio');
    const [tkRC, setTkRc] = useState('');
    const [aprova, setAprova] = useState('');
    const [endpoint, setEndpoint] = useState('');
    const [idEnvio, setIdEnvio] = useState();
    const [eventoObj, setEventoObj] = useState({
        nome: '',
        frame: '',
    });

    const { lastJsonMessage, sendMessage } = useWebSocket(`${process.env.REACT_APP_WS_URL}?systemId=${url.dynamicRoute}`, {
        onOpen: (event) => {
            console.log(`Connected to App WS`);
            setSocket(event.target);
        },
        onMessage: () => {
            if (endpoint === 'visitantes' && eventoObj.nome === '') setEventoObj({ nome: 'Visitantes', frame: frameDefault });
            if (lastJsonMessage && lastJsonMessage.aprova) setAprova(lastJsonMessage.aprova);
            if (lastJsonMessage && lastJsonMessage.evento && !sent) setEventoObj({ nome: lastJsonMessage.evento, frame: lastJsonMessage.frame === '' ? frameDefault : lastJsonMessage.frame });
        },
        onError: (event) => { console.error(event) },
        shouldReconnect: (closeEvent) => eventoObj.nome && eventoObj.nome.length > 0 && !sent ? false : true,
        reconnectInterval: 1000
    });

    useEffect(() => {
        const isMobileFlag = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
        setIsMobile(isMobileFlag);
    }, [])

    useEffect(() => {
        setEndpoint(url.dynamicRoute)
    }, [])

    useEffect(() => {
        setMAX_ENVIOS(endpoint === 'visitantes' ? 2 : 20)
    }, [])

    /*     useEffect(() => {
            if (eventoObj.nome && eventoObj.nome.length > 0 && eventoObj.frame && eventoObj.frame.length > 0) socket.close();
        }, [eventoObj])
    */
    useEffect(() => {
        if (eventoObj.frame && eventoObj.frame.length > 0) {
            toDataUrl(eventoObj.frame, function (myBase64) {
                setEventoObj(prevState => ({ ...prevState, frame: { image: myBase64 } }));
            });
        }
    }, [eventoObj.frame])

    useEffect(() => {
        if (localStorage.getItem('Envios')) {
            const object = JSON.parse(localStorage.getItem('Envios'));
            const timestamp = object.timestamp;
            const value = parseInt(object.value);
            if (value) setEnvios(value);
            if (object.wait) return compareTime(timestamp);
        }
    }, [])

    useEffect(() => {
        if (localStorage.getItem('Whois')) {
            const whoIs = JSON.parse(localStorage.getItem('Whois'));
            if (whoIs.nome !== '') {
                setNome(whoIs.nome);
                if (whoIs.avatar !== '') {
                    setAvatarMedia({ image: whoIs.avatar });
                    return setStep(step + 1);
                }
            }
        }
    }, [])

    useEffect(() => {
        if (files && files.length > 0) return;
        setFiles(AVATAR_FILES());
    }, [])

    useEffect(() => {
        if (envios === MAX_ENVIOS && !JSON.parse(localStorage.getItem('Envios')).wait) {
            const object = { value: envios, timestamp: new Date().getTime(), wait: true };
            window.localStorage.setItem('Envios', JSON.stringify(object));
        }
    }, [envios])

    useEffect(() => {
        setTimeout(() => setMessage(''), 5000)
    }, [message.length > 0])

    useEffect(() => {
        document.title = `Envie sua Foto - ${eventoObj && eventoObj.nome ? eventoObj.nome : 'StoriesEvento'}`;
    }, [eventoObj])

    function onSubmit(event) {
        setMessage('');
        if (validate(nome, media)) {
            event.target.innerHTML = '<span class="loader"></span>';
            //recaptchaRef.current.execute();
            setTkRc('sem captcha, voltar para o normal depois');
        }
    }

    useEffect(() => {
        if (tkRC && tkRC.length > 0) {
            const avatarToSend = avatarMedia !== '' ? avatarMedia.image : avatarDraw;
            sendContent(media.image, nome.replaceAll('/', '-'), avatarToSend, legendaRef.current.value, endpoint)
                .then(res => {
                    if (res) {
                        setIdEnvio(res.id);
                        setTimeout(() => { return setSent(true) }, 2000);
                    }
                    else {
                        btnSubmitRef.current.innerHTML = 'Publicar';
                        setMessage('O envio ainda não está ativo. Por favor, aguarde.');
                    }
                })
        }
    }, [tkRC])

    function onChangeRC(e) {
        setTkRc(e)
    }

    function validate(name, media) {
        if (name.length < 1 || media.length < 1)
            return setMessage('Preencha o formulário corretamente');
        return true;
    }

    async function onImage(event) {
        if (event.target.files && event.target.files[0] && event.target.className === 'avatar') {
            await resizeFile(event.target.files[0], 'avatar');
            setLabelAvatarClass('fadein');
        }
        if (event.target.files && event.target.files[0] && event.target.className === 'media') {
            let img = new Image()
            img.src = window.URL.createObjectURL(event.target.files[0])
            img.onload = async () => {
                if (img.width < img.height) {
                    await resizeFile(event.target.files[0]);
                    setLabelClass('fadein');
                }
                else setMessage('Por favor, envie uma foto no formato VERTICAL.')
            }
        }
    }

    function resizeFile(file, avatar = '') {
        new Promise((resolve) => {
            Resizer.imageFileResizer(
                file,
                avatar && avatar.length > 0 ? 150 : 1200,
                avatar && avatar.length > 0 ? 150 : 1200,
                "WEBP",
                80,
                0,
                (uri) => {
                    avatar && avatar.length > 0 ? setAvatarMedia({ image: uri }) : setMedia({ image: uri });
                    resolve(uri);
                },
                "base64"
            );
        });
    }

    function toDataUrl(url, callback) {
        var xhr = new XMLHttpRequest();
        xhr.onload = function () {
            var reader = new FileReader();
            reader.onloadend = function () {
                callback(reader.result);
            }
            reader.readAsDataURL(xhr.response);
        };
        xhr.open('GET', url);
        xhr.responseType = 'blob';
        xhr.send();
    }

    function handleCapturaClique(data) {
        if (socket && socket.readyState === WebSocket.OPEN) {
            if (data[0] === 'review') {
                if (data[1] === '') return;
                divCommentRef.current.classList.add('fadeout');
                setTimeout(() => divCommentRef.current.remove(), 490);
            }
            sendMessage(JSON.stringify({ endpoint: endpoint, id: idEnvio, action: data }));
        } else {
            console.error('WebSocket não está aberto.');
        }
    }

    function thanks() {
        const object = { value: envios + 1, timestamp: new Date().getTime(), wait: false };
        window.localStorage.setItem('Envios', JSON.stringify(object));
        const whoIs = { nome: nome, avatar: avatarMedia && avatarMedia.image && avatarMedia.image.length > 0 ? avatarMedia.image : '' }
        window.localStorage.setItem('Whois', JSON.stringify(whoIs));

        return (
            <div>
                <div className='thanks fadein'>
                    <h1>Obrigado, {nome.indexOf(' ') ? nome.split(' ')[0] : nome}</h1>
                    <p>Sua Foto foi enviada!</p>
                    {
                        eventoObj && eventoObj.nome && eventoObj.nome.length > 0 && eventoObj.frame && eventoObj.frame.image && eventoObj.frame.image.length > 0
                            ? <FramePhoto endpoint={endpoint} evento={eventoObj.nome} nome={nome} media={media.image} frame={eventoObj.frame.image} capturaClique={handleCapturaClique} />
                            : (
                                <div><img src={media.image} alt={`Foto de ${nome}`} /></div>
                            )
                    }

                    <div className='storyPendente'>
                        {
                            endpoint !== 'visitantes'
                                ? aprova === 'auto' ? 'Sua foto já está no painel de exibição!' : 'Sua publicação está pendente e aguardando aprovação.'
                                : <Link to="/#stories" style={{ color: '#57d8ff', textDecoration: 'underline' }}>Veja sua foto no Stories de VISITANTES</Link>
                        }
                    </div>
                </div>
                <div className='comment' ref={divCommentRef}>
                    <textarea ref={commentRef} placeholder='Gostou?! Digite aqui seu comentário' rows={4} maxLength={80}></textarea>
                    <button onClick={() => handleCapturaClique(['review', commentRef.current.value])}>Enviar</button>
                </div>
                <Assinatura aplicativo='stories' />
            </div>
        )
    }

    function compareTime(timestamp) {
        const now = new Date().getTime();
        if ((now - timestamp) > 1800000) {
            const object = { value: 0, timestamp: 0, wait: false };
            window.localStorage.setItem('Envios', JSON.stringify(object));
            return setEnvios(0);
        }
    }

    function blocked() {
        return (
            <div>
                <div className='thanks fadein'>
                    <h1>Muito obrigado!</h1>
                    <p>Você atingiu seu limite de envios. Agradecemos sua participação.</p>
                </div>
                <Assinatura aplicativo='stories' />
            </div>
        )
    }
    function onChildChanged(data) {
        setAvatarDraw(data);
    }

    function stepOne() {

        function validaS1() {
            if (!nome && nameRef.current.value && nameRef.current.value.length > 0) {
                setNome(nameRef.current.value);
                setStep(step + 1);
            }
            else if (!nome) return setMessage('Preencha o campo NOME.');
            else setStep(step + 1);
        }

        return (
            <div>
                {
                    nome
                        ? (
                            <div className='fotoENome'>
                                <h4 className='step1'>{nome}</h4>
                            </div>
                        )
                        : (
                            <div className='nome'>
                                <label>Nome</label>
                                <input type='text' ref={nameRef} maxLength={20} autoComplete='given-name' placeholder="Seu nome (até 20 caracteres)" required />
                            </div>
                        )
                }
                <div className='avatarEnvio'>
                    {
                        !avatarMedia.image
                            ? (
                                <div>
                                    <label>Escolha um avatar...</label>
                                    <AvatarSort callbackParent={(data) => onChildChanged(data)} />
                                    <label>Ou use uma imagem do seu celular</label>

                                    <label className={labelAvatarClass} htmlFor='fileAvatar'>Escolha uma foto de perfil</label>
                                    <input id='fileAvatar' type='file' style={{ display: 'none' }} accept='image/*' onChange={onImage} className='avatar' ref={avatarRef} />
                                </div>
                            )
                            : (
                                <div>
                                    <label>Foto de perfil escolhida</label>
                                    <div className='perfilEnvio'><img className='fotoDePerfil' src={avatarMedia.image} alt={nome ? nome : nameRef.current.value && nameRef.current.value.length > 0 ? `Perfil de ${nameRef.current.value}` : ''} /></div>
                                </div>
                            )
                    }
                </div>
                <button onClick={validaS1} className='to2Step' type='button'>
                    Próximo
                    <svg fill="none" stroke="currentColor" strokeWidth={1.5} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M4.5 12h15m0 0l-6.75-6.75M19.5 12l-6.75 6.75" />
                    </svg>
                </button>
            </div>
        )
    }

    function stepTwo() {
        return (
            <div>
                <div className='fotoENome fadein'>
                    <div className='perfilEnvio'>
                        <img className='fotoDePerfil' src={avatarMedia !== '' ? avatarMedia.image : files[avatarDraw]} alt={`Perfil de ${nome}`} />
                    </div>
                    <h4>{nome}</h4>
                </div>

                {
                    !media.image
                        ? (
                            <div className='envioFoto'>
                                <div className='fotoInstrucao'>
                                    <img src={imgGarotaCelular} alt='Tire a foto com o celular na vertical' />
                                    <p>Sua foto estará disponível no formato <span className='vertical'>vertical</span>.</p>
                                </div>
                                <div className='btnCelularFoto'>
                                    <label className={labelClass} htmlFor='file'>TIRE UMA FOTO</label>
                                    <input required id='file' type='file' style={{ display: 'none' }} accept='image/*' onChange={onImage} className='media' capture ref={mediaRef} />
                                </div>
                            </div>
                        )
                        : (
                            <div>
                                <div className='fotoPrincipalEnvio'><img className='fotoPrincipal fadein' src={media.image} alt={`Foto de ${nome}`} /></div>
                                <label>Legenda<Opcional /></label>
                                <textarea className='textLegenda' maxLength={100} placeholder="Escreva uma legenda com até 80 caracteres..." ref={legendaRef} />
                                {stepThree()}
                            </div>
                        )
                }
            </div>
        )
    }

    function politicaModal() {
        if (showModal) setShowModal(false);
        else setShowModal(true);
    }

    function modalClose() {
        setShowModal(false);
    }

    function modalComecarClose() {
        setComecar(false);
    }

    function stepThree() {
        return (
            <div>
                <div className='botaoSend'>
                    <div className='disclaimer'>
                        <input type="checkbox" onChange={politica} /><p>Concordo com a <button type='button' onClick={politicaModal}>Política de Privacidade & Termos de Uso</button></p>
                    </div>
                    <button type='button' ref={btnSubmitRef} disabled={disabled} onClick={onSubmit}>Publicar</button>
                    <ReCAPTCHA
                        ref={recaptchaRef}
                        size="invisible"
                        sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
                        onChange={onChangeRC}
                    />
                </div>
            </div>
        )
    }

    function politica(event) {
        if (event.target.checked) setDisabled(false);
        else setDisabled(true);
    }

    function loadStart() {
        setTimeout(() => loadStartRef.current ? loadStartRef.current.classList.add('fadeout') : '', 1510);
        setTimeout(() => setStLoadStart(false), 2000);
        return (
            <div className='loadStart' ref={loadStartRef}>
                <div className='container'>
                    <Assinatura aplicativo='stories' />
                    <div className='loaderSpan'><span className="loader"></span></div>
                </div>
            </div>
        )
    }

    return (
        <main className='envioScreen'>
            {
                stLoadStart
                    ? loadStart()
                    : <React.Fragment></React.Fragment>
            }
            <div className='send'>
                {
                    isMobile
                        ? (
                            envios && envios >= MAX_ENVIOS
                                ? blocked()
                                : sent
                                    ? thanks()
                                    : (
                                        <div>
                                            <Assinatura aplicativo='stories' />
                                            <div className='sendBox'>
                                                <div className='titleSend'>
                                                    {
                                                        eventoObj.nome !== ''
                                                            ? (<div className='nomeEventoEnvio'>{eventoObj.nome}</div>)
                                                            : <React.Fragment></React.Fragment>
                                                    }
                                                    <h1>Envie sua Foto</h1>
                                                </div>
                                                {
                                                    eventoObj.nome !== ''
                                                        ? step === 1
                                                            ? stepOne()
                                                            : step === 2
                                                                ? stepTwo()
                                                                : <React.Fragment></React.Fragment>
                                                        : (
                                                            <div>
                                                                <svg className='envioInativo' fill="none" stroke="currentColor" strokeWidth={0.8} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
                                                                    <path strokeLinecap="round" strokeLinejoin="round" d="M15.182 16.318A4.486 4.486 0 0012.016 15a4.486 4.486 0 00-3.198 1.318M21 12a9 9 0 11-18 0 9 9 0 0118 0zM9.75 9.75c0 .414-.168.75-.375.75S9 10.164 9 9.75 9.168 9 9.375 9s.375.336.375.75zm-.375 0h.008v.015h-.008V9.75zm5.625 0c0 .414-.168.75-.375.75s-.375-.336-.375-.75.168-.75.375-.75.375.336.375.75zm-.375 0h.008v.015h-.008V9.75z" />
                                                                </svg>
                                                                <p className='envioInativo'>
                                                                    {
                                                                        eventoObj.nome && eventoObj.nome.toLowerCase().replaceAll(' ', '-') !== endpoint
                                                                            ? 'Algo errado não está certo...'
                                                                            : <React.Fragment>O envio ainda não está ativo...<br />Por favor, aguarde.</React.Fragment>
                                                                    }
                                                                </p>
                                                            </div>
                                                        )
                                                }
                                                {
                                                    message && message.length > 0
                                                        ? (<div className='formMessage'>{message}</div>)
                                                        : <React.Fragment></React.Fragment>
                                                }
                                            </div>
                                        </div>
                                    )
                        )
                        : (
                            <div>
                                <Assinatura aplicativo='stories' />
                                <div className='sendBox'>
                                    <h1>Poxa...</h1>
                                    <p>O envio de fotos só é permitido através do celular.</p>
                                </div>
                            </div>
                        )
                }
            </div>
            {
                showModal
                    ? <PoliticaTermos callbackParent={modalClose} />
                    : <React.Fragment></React.Fragment>
            }
            {
                comecar && envios === 0 && eventoObj.nome !== ''
                    ? <ModalComecar callbackParent={modalComecarClose} />
                    : <React.Fragment></React.Fragment>
            }
            <AnimaBackground />
        </main>
    );

}

export default Envio;
