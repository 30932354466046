import React, { useState, useEffect, useRef } from "react";
import QRCodeGen from "../../components/QRcodeGen/QRcodeGen";
import Stories from "./Stories";
import useWebSocket from "react-use-websocket";
import { Link } from "react-router-dom";
import imageA from "../../images/visitantes/meninas-selfie.webp";
import imageB from "../../images/visitantes/meninas-selfie-flash.webp";

function Home() {
    const [showInitialAnimation, setShowInitialAnimation] = useState(true);
    const [isSecondImage, setIsSecondImage] = useState(false);
    const [showText, setShowText] = useState(false);
    const [showMainContent, setShowMainContent] = useState(false);
    const [showSaibaMais, setShowSaibaMais] = useState(false);

    const storiesRef = useRef(null);
    const endpoint = 'visitantes';
    const url = process.env.REACT_APP_FRONT_URL + '/stories/envio/' + endpoint;
    const size = 170;

    const [isMobile, setIsMobile] = useState(false);
    const [aprova, setAprova] = useState({});

    useEffect(() => {
        if (showInitialAnimation) {
            setTimeout(() => {
                setShowText(true);
            }, 3000);

            setTimeout(() => {
                setIsSecondImage(true);
            }, 5000);

            setTimeout(() => {
                setShowInitialAnimation(false);
                setShowMainContent(true);
            }, 7500);
        }
    }, [showInitialAnimation]);

    useEffect(() => {
        if (storiesRef.current && window.location.hash === '#stories')
            storiesRef.current.scrollIntoView({ behavior: 'smooth' });
    }, [storiesRef.current]);

    useEffect(() => {
        const isMobileFlag = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
        setIsMobile(isMobileFlag);
    }, []);

    const { lastJsonMessage } = useWebSocket(`${process.env.REACT_APP_WS_URL}?systemId=${endpoint}`, {
        onOpen: () => {
            console.log(`Connected to App WS`);
        },
        onMessage: () => {
            if (lastJsonMessage) {
                if (lastJsonMessage && lastJsonMessage.media && aprova !== lastJsonMessage)
                    setAprova(lastJsonMessage);
            }
        },
        onError: (event) => {
            console.error(event);
        },
        shouldReconnect: (closeEvent) => true,
        reconnectInterval: 1000
    });

    const InitialAnimation = () => (
        <div className="initial-animation">
            <div className="image-container">
                <img
                    src={imageA}
                    alt="Welcome"
                    className={`fullscreen-image ${isSecondImage ? 'fade-out' : ''}`}
                />
                <img
                    src={imageB}
                    alt="Welcome"
                    className={`fullscreen-image second-image ${isSecondImage ? 'fade-in' : ''}`}
                />
            </div>
            {showText && (
                <div className="centered-text fade-in">
                    <p className="gradient-text">Envie sua foto</p>
                </div>
            )}
        </div>
    );

    return (
        <div className="OpenStories Home fadein">
            {showInitialAnimation ? (
                <InitialAnimation />
            ) : (
                <>
                    <div className="area">
                        <ul className="circles">
                            <li></li>
                            <li></li>
                            <li></li>
                            <li></li>
                            <li></li>
                            <li></li>
                            <li></li>
                            <li></li>
                            <li></li>
                            <li></li>
                        </ul>
                    </div>
                    {lastJsonMessage ? (
                        <div className="context">
                            <div className="textHome">
                                <h1><span>stories</span>evento</h1>
                                <p>
                                    <span style={{
                                        fontWeight: 'bold',
                                        backgroundColor: '#57d8ff',
                                        color: '#000',
                                        padding: '0 5px'
                                    }}>
                                        Seu evento em fotos
                                    </span>
                                    <br />
                                    compartilhadas em tempo real.
                                </p>
                                <div className="saiba-mais-section">
                                    <button 
                                        onClick={() => setShowSaibaMais(!showSaibaMais)}
                                        className="saiba-mais-button"
                                        style={{
                                            background: 'none',
                                            border: 'none',
                                            color: '#57d8ff',
                                            fontSize: '1.5rem',
                                            cursor: 'pointer',
                                            display: 'inline-flex',
                                            alignItems: 'center',
                                            gap: '0px'
                                        }}
                                    >
                                        Saiba mais&nbsp;
                                        <span style={{
                                            transform: showSaibaMais ? 'rotate(180deg)' : 'rotate(0)',
                                            transition: 'transform 0.3s ease'
                                        }}>
                                            ↓
                                        </span>
                                    </button>
                                    
                                    <div 
                                        className="saiba-mais-content"
                                        style={{
                                            maxHeight: showSaibaMais ? '500px' : '0',
                                            overflow: 'hidden',
                                            transition: 'max-height 0.5s ease',
                                        }}
                                    >
                                        <p style={{ 
                                            lineHeight: '1.5',
                                            opacity: showSaibaMais ? '1' : '0',
                                            transition: 'opacity 0.3s ease'
                                        }}>
                                            Entretenimento para eventos sociais. 
                                            Com uma estação de tela física, os convidados escaneiam um QR Code 
                                            para enviar suas fotos, que são exibidas em tempo real no formato de stories. 
                                            Assim, todos podem compartilhar momentos especiais e criar uma galeria 
                                            dinâmica durante a celebração.
                                        </p>
                                    </div>
                                </div>
                                <span>
                                    Participe como visitante:<br />
                                    Escaneie o QR code com a câmera do seu celular e envie sua foto.
                                </span>
                                <div className="qrHome">{QRCodeGen({ url, size })}</div>
                                {isMobile && (
                                    <Link
                                        to={url}
                                        style={{ color: '#57d8ff', textDecoration: 'underline' }}
                                        target="_blank"
                                    >
                                        Ou acesse através deste link
                                    </Link>
                                )}
                                <button
                                    className="storiesSource"
                                    type="button"
                                    onClick={() => window.location.href = 'mailto:pedrofrn@gmail.com'}
                                >
                                    Entre em contato
                                </button>
                            </div>
                            <div id="stories" ref={storiesRef} className="areaScreen">
                                <div className="testScreen">
                                    <Stories
                                        endpoint={endpoint}
                                        evento={'visitantes'}
                                        tempo={5}
                                        showQr={false}
                                        aprova={aprova}
                                        exclui={false}
                                        quantidade={3}
                                    />
                                </div>
                            </div>
                        </div>
                    ) : (
                        <div className="loadHome">
                            <span className="loader"></span>
                        </div>
                    )}
                </>
            )}
        </div>
    );
}

export default Home;